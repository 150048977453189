<template>
    <div class="schedling">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @handRegister="handRegister" @handFromshow="handFromshow" :titlename="titlename"></Tabheader>
        </div>
        
         <div class="attheadfrom" v-show="flag">
            <el-form :inline="true" :model="searchParams" class="demo-form-inline">
                <!-- <el-form-item label="企业名称">
                    <el-select v-model="formInline.region" placeholder="活动区域">
                    <el-option label="考勤处理" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item :label="this.$t('devtable.bm')">
                    <Select @handleCheckChange="handleCheckChange"></Select>
                </el-form-item>
                <el-form-item :label="this.$t('devtable.xm')">
                    <el-input v-model="searchParams.name" :placeholder="this.$t('devtable.xm')"></el-input>
                </el-form-item>
                <el-form-item :label="this.$t('devtable.ghHH')">
                    <el-input v-model="searchParams.number" :placeholder="this.$t('devtable.ghHH')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">{{this.$t('devtable.cx')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
        <Tables @handlpages="handlpages" @handleSizeChange="handleSizeChange" :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs"></Tables>
        <Timetrackingdiolg @addTimeTrackSuccess="addTimeTrackSuccess" ref="timetrackingdiolg"></Timetrackingdiolg>
    </div>
</template>
<script>
import Tables from '@/components/table'
import Tabheader from './components/tabheader'
import Timetrackingdiolg from './components/timetrackingdiolg'
import Select from '@/components/selectree/selectchoice'
import {attendanceServer} from '../../api/uesr'
export default {
    components:{
        Tables,
        Tabheader,
        Timetrackingdiolg,
        Select
    },
    data(){
        return{
            flag:false,
            searchParams: {
                name: '',
                number: '',
                dept_id: ''
            },
            
            
            tableData: [
            ],
            flags: {
                show: false, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
            UserInfo:''
            // operation: {
                
            // },
        }
    },
    computed:{
        titlename:function(){
            return{
                name:this.$t('navLeft.tjkqcl'),
                placeholdername:this.$t('devtable.qdjcx')
            }},
        tableLabel:function(){ 
            return[
                {
                    prop: 'id',
                    label: this.$t('devtable.ygid'),
                    align: 'center'                    
                }
                ,{
                    prop: 'name',
                    label: this.$t('devtable.ygxm'),
                    align: 'center'
                },
                {
                    prop: 'number',
                    label: this.$t('devtable.ghHH'),
                    align: 'center'
                },
                {
                    prop: 'dept_name',
                    label: this.$t('devtable.bm'),
                    align: 'center'
                },
                {
                    prop: 'order_name',
                    label: this.$t('devtable.bc'),
                    align: 'center'
                }
            ]
        }
    },
    mounted () {
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getTimeTrackingList(1);
    },
    methods:{
        // 获取企业部门id
        handleCheckChange(val){
            this.searchParams.dept_id = val;
            console.log(`sel113`,this.searchParams.dept_id)
        },        
        handRegister(){
            this.$refs.timetrackingdiolg.centerDialogVisible=true
        },  
        handFromshow(){
            this.flag = !this.flag
        },
        onSubmit(){
            this.getTimeTrackingList(1);
        },
        //考勤处理添加成功
        addTimeTrackSuccess(){
            this.getTimeTrackingList(1);
        },
        handlpages(v){
          this.configs.page_index=v
            this.getTimeTrackingList();
        },
        handleSizeChange(v){
            this.configs.page_index=1
            this.configs.pagesize=v
             this.getTimeTrackingList();
        },
        handimage(){
            console.log(111)
        },
        //获取考勤处理列表
        getTimeTrackingList(pageIndex){
            const params = {
                method: 'att.users.get',
                agent_id: this.UserInfo.agent_id,
                page_index: pageIndex?pageIndex:this.configs.page_index, 
                page_size: this.configs.pagesize,                
                name: this.searchParams.name,
                number: this.searchParams.number,
                dept_id: this.searchParams.dept_id
            }

            attendanceServer(params).then(res => {
                console.log(res);
                this.tableData = res.data.data.records;
                if(params.page_index==1){
                    this.configs.total = res.data.data.recordCount;
                }
                
            })
        }        
    }
}
</script>
<style lang="less" scoped>
.schedling{
    padding: 20px;
    background-color: #fff;
    .attheadfrom{
        padding: 20px;
        margin-top: 10px;
    }
}
</style>