<template>
    <div>
        <el-dialog
            :title="$t('devtable.kqcl')"
            :visible.sync="centerDialogVisible"
            width="980px"
            append-to-body
            center>
            <div class="schdolgfoot">
                <div class="yuang"> {{$t('devtable.yg')}}</div>
                <div class="staff">
                    <div class="staffleft">
                        <div class="stafflefttop">
                            <span style="flex:2;line-height: 32px;">{{$t('devtable.bm')}} </span>
                            <!-- <el-select v-model="value" placeholder="请选择" size="small" style="flex:6;margin-right:20px">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                            <el-select v-model="value" placeholder="请选择" size="small" style="flex:4">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select> -->
                             <SelectSingle @handleCheckChange="handleCheckChange"></SelectSingle>
                        </div>
                        <el-row  style="display: flex;padding-left:55px">
                            <el-input size="small" v-model="input_name" :placeholder="$t('devtable.xm')" style="flex:3;margin-right:20px"></el-input>
                            <el-row  style="flex:2;line-height: 32px;"> <el-button type="primary" size="small" @click="searchWithName">{{$t('devtable.cx')}}</el-button></el-row>
                        </el-row>
                    </div>
                    <div class="staffright">
                        <p>{{$t('devtable.yxyg')}}</p>
                    </div>
                </div>
                <div style="padding-left:25px;overflow: hidden;">
                    <!-- <el-transfer v-model="vluass" :data="datas"></el-transfer> -->
                    <Tableva ref="transTab"></Tableva>
                </div>
            </div>
            
            <div class="details">
                <el-col :span="3" style="line-height: 32px;">
                    {{$t('devtable.ksrq')}}
                </el-col>
                <el-col :span="18">
                    <el-date-picker
                    :picker-options="pickerOptions" 
                    v-model="start_date"
                    size="small"
                    type="date"
                    :placeholder="$t('devtable.xzrq')">
                    </el-date-picker>
                </el-col>
            </div>

             <div class="details">
                <el-col :span="3" style="line-height: 32px;">
                    {{$t('devtable.jsrq')}}
                </el-col>
                <el-col :span="18">
                    <el-date-picker
                    :picker-options="pickerOptions" 
                    v-model="end_date"
                    size="small"
                    type="date"
                    :placeholder="$t('devtable.xzrq')">
                    </el-date-picker>
                </el-col>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="timeTrackingConfirm">{{$t('tablename.qd')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import SelectSingle from '@/components/selectree/selectchoice'
import Tableva from './tablelave'
import {attendanceServer} from '../../../api/uesr'
export default {
    components:{
        SelectSingle,
        Tableva
    },
    data(){
        return{
             pickerOptions: {
                disabledDate (time) {
                    // return time.getTime() < Date.now() // 选当前时间之后的时间
                    return time.getTime() > Date.now() // 选当前时间之前的时间
                }
                },
            loading:null,
            dept_id: '',
            start_date: '',
            end_date: '',
            input_name: '',
            centerDialogVisible: false,
            UserInfo:'',
            flag:true,
            numbers:null,
            numb:null,
            mnbuer:null,
            totls:null,
            strat:0,
            end:20,
            user_id:[],
            x:0
        }
    },
    mounted(){
         this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
    },
    methods:{
        
        dateFormat: function(createTime) {
            var t = new Date(createTime)// row 表示一行数据, createTime 表示要格式化的字段名称
            if(!t){
                return ''
            }
            let year = t.getFullYear()
            let month = this.dateIfAddZero(t.getMonth()+1)
            let day = this.dateIfAddZero(t.getDate())
            let hours = this.dateIfAddZero(t.getHours())
            let minutes = this.dateIfAddZero(t.getMinutes())
            let seconds = this.dateIfAddZero(t.getSeconds())
            return year + '-' + month + '-' + day+ ' '
        },
        dateIfAddZero : function (time) {
            return time < 10 ? '0'+ time : time
        },
        handleCheckChange(sel_dept){
            console.log(sel_dept);
            this.dept_id = sel_dept;
            this.$refs.transTab.getEmployeeListWithDept(sel_dept,this.input_name);
        },
        searchWithName(){
            // if(this.input_name.length == 0){
            //     this.$message({
            //         type: 'warning',
            //         message: '请输入员工姓名!'
            //     })
            // }else 
            if(this.dept_id.length == 0){
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.qxxzbm'),
                })
            }else{
                this.$refs.transTab.getEmployeeListWithDept(this.dept_id,this.input_name);
            }
        },

        //定时器
        getPageInter() {
            this.loading = this.$loading({ //加载层
                lock: true,
                text: this.$t('devtable.kqclz'),
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.6)"
            });
        
            this.intertimer = setInterval(() => {
                let userstring = this.user_id.slice(this.strat,this.end)
                this.intervalData(userstring); //每三秒调用一次方法
            }, 3000);
        },
        intervalData(userstring) {
            if (this.totls+20<this.end) {
                clearInterval(this.intertimer); //关闭定时器
                this.loading.close(); //关闭弹窗
                this.strat= 0;
                this.end=20;
            } else {
                this.renderMesh(userstring); //数据渲染
                this.strat+=20
                this.end+=20
                
            }
        },

        timeTrackingConfirm(){
            this.user_id= [];
            this.$refs.transTab.tableData_b.forEach((el,index)=>{
                this.user_id.push(el.id)
            })
            // console.log(`45`,this.user_id)
            // return false
            this.totls = this.user_id.length

            this.mnbuer = Math.ceil(this.totls/this.end)
            
            if(this.user_id.length == 0){
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.qxzyg')
                })
                return false;
            }
            if(this.start_date.length == 0 || this.end_date.length == 0){
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.qszrq')
                })
                return false;                
            }
           
            this.getPageInter()
            
        },

        renderMesh(userstring){
            const params = {
                method: 'att.data.deal',
                agent_id: this.UserInfo.agent_id,
                user_id: userstring.toString(), 
                date_start: this.dateFormat(this.start_date),
                date_end: this.dateFormat(this.end_date),
            }

            attendanceServer(params).then(res => {
                console.log(res);
                if(res.data.code == 0){
                    if (this.totls+20<this.end){
                            this.$message({
                            type: 'success',
                            message:this.$t('devtable.clcg')
                        })
                        this.$emit("addTimeTrackSuccess")
                        this.centerDialogVisible = false;
                    }
                }else{
                    this.$message({
                        type: 'warning',
                        message: this.$t('devtable.clsb')
                    })                    
                }
            })
        }

    }
}
</script>
<style lang="less" scoped>
.schdolgfoot{
    border: 1px solid #ccc;
    position: relative;
    height: 435px;
    margin-top: 20px;
    .yuang{
        width: 50px;
        height: 20px;
        text-align: center;
        background-color: #fff;
        position: absolute;
        top:-10px;
        left: 20px;
    }
    .staff{
        display: flex;
        padding: 20px;
        .staffleft{
            flex: 1;
            .stafflefttop{
                display: flex;
                margin-bottom: 20px;
                margin-left: 10px;
            }
        }
        .staffright{
            flex: 1;
            p{
                text-align: center;
            }
        }
    }
}
.details{
    padding: 25px;
}
.line{
    text-align: center;
    line-height: 30px;
}
</style>